<template>
  <div class="container">
    <div class="row justify-content-center">
      <div>
        <div class="row justify-content-center head-wrapper">
          <div class="header-selection-wrapper">
            <p class="text-light text-title-home">Al-Quran Indonesia</p>
          </div>
        </div>
        <div class="card selection-wrapper">
          <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
          <div class="card-body card-selection-menu">
            <router-link :to="{name:'quran-list'}">
              <button class="btn btn-outline-primary btn-block">Quran</button>
            </router-link>
            <router-link :to="{name:'bookmark-list'}">
              <button class="btn btn-outline-primary btn-block mt-4">Bookmark</button>
            </router-link>
            <button class="btn btn-outline-primary btn-block mt-4">Donate</button>
            <button class="btn btn-outline-primary btn-block mt-4">Download On Playstore</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script id="midtrans-script" type="text/javascript"
    src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
    data-environment="sandbox"
    data-client-key="SB-Mid-client-7CIG_--gEHHHwVVH"></script>
<script>
// eslint-disable-next-line no-unused-vars

export default {
  mounted(){
    this.test()
  },
  methods:{
    test(){
      // card data from customer input, for example
      var cardData = {
        "card_number": 4811111111111114,
        "card_exp_month": 2,
        "card_exp_year": 2025,
        "card_cvv": 123,
      };
      
      // callback functions
      var options = {
        onSuccess: function(response){
          // Success to get card token_id, implement as you wish here
          console.log('Success to get card token_id, response:', response);
          var token_id = response.token_id;
      
          console.log('This is the card token_id:', token_id);
          // Implement sending the token_id to backend to proceed to next step
        },
        onFailure: function(response){
          // Fail to get card token_id, implement as you wish here
          console.log('Fail to get card token_id, response:', response);
      
          // you may want to implement displaying failure message to customer.
          // Also record the error message to your log, so you can review
          // what causing failure for this transaction.
        }
      };
      
      // trigger `getCardToken` function
      MidtransNew3ds.getCardToken(cardData, options);
    }
  }
}
</script>

<style>

</style>